import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Projet = ({ titre, imgSrc, id, intro }) => {
 
  const [showInfos, setShowInfos] = useState(false);

  const handleMouseEnter = () => {
    setShowInfos(true);
  };

  const handleMouseLeave = () => {
    setShowInfos(false);
  };
 return (
    <div className="projet"  onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <img className="imgprojet" src={imgSrc} alt={titre} />
      <div className={`infosprojet ${showInfos ? 'visible' : ''}`}>

      <div className='txtprojet'>

          <h6 className="titreprojet">{titre}</h6>
          <p className="introprojet">{intro}</p>
        
        </div>
      </div>
      <div className={`infosprojet infos ${showInfos ? 'visible' : ''}`}>
      
        <NavLink reloadDocument to={`/projet/${id}`} className="boutonprojet bouton boutonfondviolet">
          <div >
            Voir le projet
          </div>
          <img src="media/icone/flecheblanche.svg" alt="fleche" />
        </NavLink>

      </div>
      

    </div>
  );
};

export default Projet;

