const aboutData = {
  front: [
    "HTML",
    "CSS/SCSS",
    "Javascript",
    "Typescript",
    "Nodejs",
  ],
  back: [
    "PHP",
    "SQL",
    "C#",
    "Java",
    "Symfony"
  ],
  framework :[
    "ReactJS",
    "Bootstrap",
    "TailwindCSS",
    "Unity",
    "Android Studio"
  ],
  outil:[
    "Git",
    "Webflow",
    "WordPress",
    "Joomla!"
  ],
  adobe: [
  "Photoshop",
  "Indesign",
  "Illustrator",
  "After effect",
  ]
  ,
  design: [
  "Figma",
  "Whimsical",
  ],
  modelisation:[
    "Blender",
  ],
  parcours: {
    1: {
      nom : "BUT MMI Pôle universitaire de Vichy",
      annee : "2021 - 2024",
      description : "Étudiante en Métiers du Multimédia et de l'Internet (MMI) avec une spécialisation en développement web, dispositif interactif. Passionné par la création numériques, je développe des compétences approfondies en conception web, programmation interactive et création de jeux. Mon parcours MMI me permet d'allier créativité et expertise technique pour réaliser des projets captivants et fonctionnels."
  },
  2: {
    nom : "Baccalauréat STD2A",
    annee : "2018 - 2021",
    description : "Détentrice d'un baccalauréat en Sciences et Technologies du Design et des Arts Appliqués (STD2A), mon parcours reflète mon engagement envers la créativité et le design. Grâce à une formation pluridisciplinaire, j'ai acquis une solide compréhension des concepts artistiques et techniques, me permettant d'explorer et de concevoir des solutions visuelles innovantes. Mon baccalauréat STD2A a nourri ma passion pour l'esthétique et le processus de création, des atouts que j'intègre dans mes projets de développement web."
}
},
experience: {
  2: {
    nom : "Stage de seconde année - Agence web Internet Évolution",
    annee : "2023",
    description : "J'ai effectué un stage de 8 semaines au sein de l'agence web internet Évolution en tant que développeuse web. Pendant ce stage j'ai eu l'opportunité de participé à la refonte graphique de la boutique du logiciel VDIgiciel, un logiciel de gestion de VDI. J'ai réalisé l'intégration des maquettes et la mise en place de la nouvelle charte graphique sur l'entièreté de la boutique. Pour cela j'ai travaillé avec Bootstrap 5, j'ai produit des interfaces responsives en mobile first à l'aide de ce framework. De plus j'ai dû reprendre le code existant du logiciel qui utilisait CodeIgniter, un framework PHP utilisant le système de MVC."
},
1: {
  nom : "Stage de fin d'études - Agence web Internet Évolution",
  annee : "2024",
  description : "J'ai effectué un stage de 14 semaines au sein de l'agence web Internet Évolution en tant que développeuse web. Durant ce stage, j'ai participé à divers projets, allant de l'intégration web et la création de sites WordPress et Joomla, jusqu'à la réalisation de maquettes Figma pour des sites internet et des applications mobiles. J'ai travaillé à la mise en place de l'offre VisiWEB, une solution de location de sites vitrines pour petites entreprises. J'ai mené à bien un projet de bout en bout, incluant l'étude de marché, la réalisation du modèle de site, la création de flyers promotionnels, et le développement des premiers sites pour les clients. J'ai développé des compétences en WordPress, Joomla, et TailwindCSS, et amélioré mes compétences en intégration web et en webdesign. J'ai également travaillé avec Git, la suite Adobe, et diverses autres technologies et outils de développement."
}
}

 };
  export default aboutData;