import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Projet from '../components/Projet';
import { NavLink } from 'react-router-dom';

import projectsData from '../data/DataProject';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


const Home = () => {
    const [hoveredImageIndex, setHoveredImageIndex] = useState(null);

    const handleButtonHover = (imageIndex) => {
        setHoveredImageIndex(imageIndex);
    };

    const handleButtonLeave = () => {
        setHoveredImageIndex(null);
    };

    const containerCompRef = useRef(null);
    const containerProjetRef = useRef(null);
    const Comp1 = useRef(null);
    const Comp2 = useRef(null);
    const Comp3 = useRef(null);
    const projetRef = useRef(null);
    const containerContact = useRef(null);

    const refsToObserve = [containerCompRef, Comp1, Comp2, Comp3, containerProjetRef, projetRef, containerContact];

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            refsToObserve.forEach((ref) => {
                const elementTop = ref.current.getBoundingClientRect().top;
                if (elementTop + 50 < windowHeight) {
                    ref.current.classList.add('active');
                } else {
                    ref.current.classList.remove('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            <Navigation />
            <div className='main'>
                <div className="entete-container">
                    <div className='entete  '>
                        <h1 className='fade-in-accueil-titre'>Hey 👋, je suis Oriane <br />Développeuse
                            <span> Front-end</span></h1>
                        <p className='fade-in-accueil-texte'>J'aime l'intégration web et la programmation. Je suis actuellement étudiante en BUT MMI parcours Développement web et dispositifs interactifs ( Métiers du multimédia et de l’Internet ) à Vichy.</p>

                        <NavLink onMouseEnter={() => handleButtonHover(6)}
                            onMouseLeave={handleButtonLeave} to="/contact" className="boutonfondviolet bouton fade-in-accueil-btn">
                            <div >
                                Me contacter
                            </div>
                            <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 6 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                        </NavLink>
                    </div>
                    <div className='asset-entete  fade-in-accueil-asset'>
                        <img
                            src="/media/asset400.png"
                            srcSet="/media/asset400.png 400w, /media/asset.png 800w, /media/asset1200.png 1200w"
                            sizes="(max-width: 767px) 400px, (max-width: 1023px) 800px, 1200px"
                            alt="illustration"
                        />
                    </div>
                </div>
                <div className='container-comp '>
                    <div className="entete-comp  slide-in-bottom" ref={containerCompRef}>
                        <div className="tag"><span>Mes compétences </span></div>
                        <h3>Ma formation m’a offert
                            <br /> un <span>large éventail de compétences </span> </h3>
                        <NavLink onMouseEnter={() => handleButtonHover(0)}
                            onMouseLeave={handleButtonLeave} to="/contact" className="bouton" >
                            <div >
                                Me contacter
                            </div>
                            <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 0 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                        </NavLink>
                    </div>
                    <div className="content-comp">
                        <div className="comp slide-left" ref={Comp1}>
                            <div className="img-comp">
                                <img
                                    src="/media/dev400.jpg"
                                    srcSet="/media/dev400.jpg 400w, /media/dev.jpg 800w"
                                    sizes="(max-width: 767px) 400px, 800px"
                                    alt="lignes de code"
                                />
                            </div>
                            <div className="info-comp">
                                <div className="logo-comp">
                                    <img src="/media/logo/react.png" alt="logo" />
                                    <img src="/media/logo/typescript.png" alt="logo" />
                                    <img src="/media/logo/bootstrap.png" alt="logo" />

                                </div>
                                <div className='txt-comp'>
                                    <h5>Développement Web Front end</h5>
                                    <p>Avec une passion pour la création d'expériences visuelles captivantes, je maîtrise l'art du développement front end en utilisant HTML, CSS et JavaScript pour traduire des concepts de design en interfaces interactives et esthétiques, offrant ainsi aux utilisateurs une expérience en ligne immersive.</p>
                                    <NavLink onMouseEnter={() => handleButtonHover(1)}
                                        onMouseLeave={handleButtonLeave} to="/projects" className="bouton">
                                        <div >
                                            Voir mes projets
                                        </div>
                                        <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 1 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="comp inverse slide-right" ref={Comp2}>
                            <div className="info-comp">
                                <div className="logo-comp">
                                    <img src="/media/logo/photoshop.png" alt="logo" />
                                    <img src="/media/logo/ai.png" alt="logo" />
                                    <img src="/media/logo/figma.svg" alt="logo" />
                                </div>
                                <div className='txt-comp'>
                                    <h5>Design</h5>
                                    <p>Mon approche multidisciplinaire englobe à la fois le design d'identité visuelle et la création de maquettes de site. Je fusionne des concepts de design captivants avec des structures de site conviviales, offrant ainsi des solutions complètes qui non seulement séduisent visuellement, mais guident également les utilisateurs à travers des expériences en ligne cohérentes et bien pensées.</p>
                                    <NavLink onMouseEnter={() => handleButtonHover(2)}
                                        onMouseLeave={handleButtonLeave} to="/projects" className="bouton">
                                        <div >
                                            Voir mes projets
                                        </div>
                                        <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 2 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="img-comp">
                                <img
                                    src="/media/design400.jpg"
                                    srcSet="/media/design400.jpg 400w, /media/design.jpg 800w"
                                    sizes="(max-width: 767px) 400px, 800px"
                                    alt="Indesign, illustrator, photoshop"
                                />

                            </div>
                        </div>
                        <div className="comp slide-left" ref={Comp3}>
                            <div className="img-comp">
                                <img
                                    src="/media/photographie400.jpg"
                                    srcSet="/media/photographie400.jpg 400w, /media/photographie.jpg 800w"
                                    sizes="(max-width: 767px) 400px, 800px"
                                    alt="illustration composée de trois photos, des fleurs, un couple et une biche"
                                />
                            </div>
                            <div className="info-comp">
                                <div className="logo-comp">
                                    <img src="/media/icone/camera.png" alt="logo" />

                                </div>
                                <div className='txt-comp'>
                                    <h5>Photographie et vidéo</h5>
                                    <p>En tant que photographe, je saisis l'instant présent avec une sensibilité particulière pour les détails. Chaque cliché est pensé avec soin, reflétant ma passion pour la recherche de la beauté dans le quotidien. Mes photographies racontent des histoires, capturant des moments éphémères et figeant l'émotion pour l'éternité.</p>
                                    <NavLink onMouseEnter={() => handleButtonHover(3)}
                                        onMouseLeave={handleButtonLeave} to="/projects" className="bouton">
                                        <div >
                                            Voir mes projets
                                        </div>
                                        <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 3 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-projet slide-in-bottom" ref={containerProjetRef}>
                    <div className="entete-projet">
                        <div className="tag"><span>Portfolio</span></div>
                        <h3>Voici quelques uns de
                            <br /> <span>mes projets.</span> </h3>
                        <NavLink onMouseEnter={() => handleButtonHover(4)}
                            onMouseLeave={handleButtonLeave} to="/projects" className="bouton">
                            <div >
                                Voir mes projets
                            </div>
                            <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 4 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                        </NavLink>
                    </div>
                    <div className='content-projet slide-in-bottom' ref={projetRef}>
                        {projectsData.slice(projectsData.length-3, projectsData.length).map((project) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <NavLink key={project.id} to={`/projet/${project.id}`} className="projet-link"  >
                                <Projet
                                    titre={project.titreprojet}
                                    intro={project.introprojet}
                                    imgSrc={project.imgentete[0]}
                                    comp={project.comprequises}
                                    id={project.id}
                                />
                            </NavLink>
                        ))}

                    </div>
                </div>
                <div className="container-contact-bloc slide-in-bottom" ref={containerContact}>
                    <div className="content-contact-bloc">
                        <h2>Un projet ? Contactez moi 📃!</h2>
                        <p>Je réponds généralement en moins de 24 heures  !</p>
                        <NavLink onMouseEnter={() => handleButtonHover(5)}
                            onMouseLeave={handleButtonLeave} to="/contact" className="boutonfondviolet bouton">
                            <div >
                                Me contacter
                            </div>
                            <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 5 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                        </NavLink>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );

};
export default Home;