
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

// Import des sources d'images
import menuIconClosed from '../styles/assets/img/menu.svg';
import menuIconOpen from '../styles/assets/img/menuclose.svg';

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [navbar, setNavbar] = useState(false);
    const [animnavbar, setAnimNavbar] = useState(true);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    const menuIconSource = showNavbar ? menuIconOpen : menuIconClosed;
    const logoIconSource = navbar ? '/media/logonoir.svg' : '/media/logoblanc.svg';
    
  const changeNavBar = () => {
    if (window.scrollY >= 400){
        setNavbar(true);
        setAnimNavbar(false);
    }
    else if (window.scrollY === 0){
        setAnimNavbar(true);
    }
    else{
        setNavbar(false)
    }
  }

  window.addEventListener('scroll', changeNavBar);
  window.addEventListener('load', changeNavBar);
    return (
        <nav className={`navbar  ${showNavbar && 'active'}`}>
            <div className={navbar ? 'fond-container active' : 'fond-container' } id={animnavbar ? 'noanim' : 'anim' } >
            <div className="container">
                <div className="logo" >
                    <img src={logoIconSource} alt="logo" />
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <img src={menuIconSource} alt="Menu icone" />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/" className={navbar ? 'blacklink' : 'whitelink'}>Accueil</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" className={navbar ? 'blacklink' : 'whitelink'}>À propos</NavLink>
                        </li>
                        <li>
                            <NavLink to="/projects" className={navbar ? 'blacklink' : 'whitelink'}>Projets</NavLink>

                        </li>
                        <li>
                            <NavLink to="/contact" className={navbar ? ' boutonfondviolet' : 'whitelink boutonfondviolet'}>Contact</NavLink>

                        </li>
                    </ul>
                </div>
            </div>
            </div>
        </nav>
    )
}

export default Navbar

