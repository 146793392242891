import React from 'react';

const Footer = () => {
  return (
    <footer >
      <div>
        <div className='logofooter'>
          <img src="/media/logoblanc.svg" alt="logo" />
        </div>

        {/* <div className="navfooter">
          <a href="/">Accueil</a>
          <a href="/about">À propos</a>
          <a href="/projects">Projets</a>
          <a href="/contact">Contact</a>
        </div> */}

        <div className="contactfooter">
          <a href="https://www.linkedin.com/in/oriane-benatan-b18395241/">
            <img src="/media/logo/linkedin_white.png" alt="linkedin" />
          </a>
          <a href="https://instagram.com/ori_bnt?igshid=MzNlNGNkZWQ4Mg==">
            <img src="/media/logo/insta_white.png" alt="insta" />
          </a>
          {/* <a href="#">
            <img src="/media/logo/facebook_white.png" alt="facebook" />
          </a> */}

        </div>

      </div>
      <div className='end'>
        <hr />
        <p >
          © 2024 Oriane Benatan - Tous droits réservés
        </p>
      </div>
    </footer>
  );
};

export default Footer;
