import React from 'react';
import { NavLink } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
const SAE = () => {

  const imagesBack = [
    {
      original: "../media/projets/sae/symfony.png",
      thumbnail: "../media/projets/sae/symfony.png",
    },
    {
      original: "../media/projets/sae/ia.png",
      thumbnail: "../media/projets/sae/ia.png",
    },
    {
      original: "../media/projets/sae/navmeshSurface.png",
      thumbnail: "../media/projets/sae/navmeshSurface.png",
    },
    {
      original: "../media/projets/sae/schemaIA.jpg",
      thumbnail: "../media/projets/sae/schemaIA.jpg",
    },
  ];
  const imagesEntre = [
    {
      original: "../media/projets/sae/temps.png",
      thumbnail: "../media/projets/sae/temps.png",
    },
    {
      original: "../media/projets/sae/bmc.png",
      thumbnail: "../media/projets/sae/bmc.png",
    },
    {
      original: "../media/projets/sae/omni.png",
      thumbnail: "../media/projets/sae/omni.png",
    },
  ];
  const imagesFront = [
    {
      original: "../media/projets/sae/accueil.png",
      thumbnail: "../media/projets/sae/accueil.png",
    },
    {
      original: "../media/projets/sae/assets.png",
      thumbnail: "../media/projets/sae/assets.png",
    },
    {
      original: "../media/projets/sae/asset.png",
      thumbnail: "../media/projets/sae/asset.png",
    }, {
      original: "../media/projets/sae/presenation.png",
      thumbnail: "../media/projets/sae/presentation.png",
    },
  ];
  const images3d = [
    {
      original: "../media/projets/sae/3d.png",
      thumbnail: "../media/projets/sae/3d.png",
    },
    {
      original: "../media/projets/sae/normal.png",
      thumbnail: "../media/projets/sae/normal.png",
    },
    {
      original: "../media/projets/sae/homme.png",
      thumbnail: "../media/projets/sae/homme.png",
    },
  ];
  const imagesDispo = [
    {
      original: "../media/projets/sae/android.png",
      thumbnail: "../media/projets/sae/android.png",
    },
    {
      original: "../media/projets/sae/codeKintsugi.png",
      thumbnail: "../media/projets/sae/codeKintsugi.png",
    },
  ];
  const imagesManagement = [
    {
      original: "../media/projets/sae/git.png",
      thumbnail: "../media/projets/sae/git.png",
    },
    {
      original: "../media/projets/sae/jira.png",
      thumbnail: "../media/projets/sae/jira.png",
    },
  ];
  return (
    <><div className='bg-sae'>
      <Navigation />
      <div className='Pageprojet pages fade-in'>
        <div className='entete'>
          <div className='haut'>

            <div className='titreprojet'>
              <h2>SAE 501 - Jeu Unity Kintsugi Tracer</h2>
              <p>Concevoir un dispositif interactif</p>
            </div>


          </div>
          <div className='images'>
            <img src="../media/projets/sae/1.png" alt="accueil du jeu" />
            <img src="./media/projets/sae/codeKintsugi.png" alt="code du jeu" />
          </div>
        </div>
        <div className="corps sae">
          <div className='intro'>
            <h4>SAE 501 - Jeu Unity Kintsugi Tracer</h4>
            <><p>
              Le projet SAE 501 imposait des contraintes claires, nécessitant la création d'un <span> jeu sous Unity à la première personne</span>, adoptant un style Low-poly. Cette page va reprendre les différentes matières abordées dans le cadre de ce projet et les différents livrables rendus. Ce projet avait pour but de développer deux des cinq compétences du système d'évaluation du BUT, Développer et Entreprendre.
            </p><br></br></>
            <><p>
              Le thème central était <span> la résilience</span>, définie selon quatre perspectives : mécanique, psychologique, écologique et informatique.
              La résilience implique la capacité à surmonter des épreuves et à retrouver une normalité après des périodes difficiles.
              Le jeu devait être ancré dans l'un des trois contextes suggérés : Martin Luther King, le jeu de société "La Grange" de l'INRAE, ou un contexte de choix propre à
              l'équipe. De plus, le cahier des charges recommandait fortement l'intégration d'une phase de quest design, permettant aux joueurs de prendre des décisions impactantes
              avec des conséquences diverses, potentiellement irréversibles.
              En somme, le défi de cette SAE consistait à <span> développer un jeu immersif explorant le concept de résilience au travers de choix significatifs et une narration.</span>

            </p><br></br></>
            <><p>
              J'ai été impliqué dans la réalisation de plusieurs aspects du projet, notamment la gestion de projet, le développement front-end et back-end, la conception 3D, la programmation mobile,
              et la création de contenu interactif. Ces différentes tâches m'ont permis de développer des compétences variées et de contribuer de manière significative à la réalisation du projet.
              <br></br> Si vous souhaitez avec des informations sur le jeu réalisé au cours de ce projet, je vous invite à consulter la page dédiée à <NavLink to="/projet/10">la présentation du jeu.</NavLink>
            </p><br></br></>
          </div>

          <h6>Sommaire interactif</h6>
          <div className='menuSAE'>
            <div className='col-menu'>
              <a href="#anglais">R501 - Anglais</a>
              <a href="#management">R502 - Management et assurance qualité</a>
              <a href="#entrepreneuriat">R503 - Entrepreneuriat</a>
              <a href="#ppp">R504 - Projet personnel et professionnel</a>
              <a href="#front">R505G - Développement front avancé</a>
            </div>
            <div className='col-menu'>
              <a href="#back">R506G - Développement back avancé</a>
              <a href="#dispositifs-interactifs">R507G - Dispositifs interactifs</a>
              <a href="#hebergement">R508G - Hébergement et cybersécurité</a>
              <a href="#3d">R509G - Conception 3D</a>
              <a href="#conception">R510G - Conception de jeux</a>
              <a href="#bilan">Perspectives pour l'avenir</a>
            </div>
          </div>

          <><div className='matiere' id='anglais'>
            <div>
              <h6> R501 - Anglais (AC3504)</h6>

              <><p>
                Dans le cadre de la matière R501 - Anglais (AC3504), j'ai réalisé une <span>vidéo réflexive. </span>
                Mon objectif était de présenter de manière individuelle mon rôle au sein du projet,
                tout en abordant les difficultés auxquelles j'ai été confrontée ainsi que mes points forts.
              </p><br></br></>
              <><p>
                L'exercice s'est avéré particulièrement exigeant, notamment en raison de la nécessité de
                m'exprimer de façon continue face à la caméra, tout en utilisant l'anglais comme langue
                de communication. Une des principales difficultés que j'ai rencontrées résidait dans
                l'obligation de maintenir une fluidité de communication tout en discutant de détails complexes liés au projet.
              </p><br></br></>
              <><p>
                Ce défi a entraîné de nombreuses prises, illustrant la complexité de trouver l'équilibre entre le contenu technique du projet
                et la clarté de l'expression en anglais. Les multiples tentatives ont témoigné de ma persévérance pour obtenir un résultat satisfaisant.
              </p><br></br></>
              <><p>
                En conclusion, cette expérience a non seulement renforcé mes compétences en <span>communication en anglais</span>,  mais a également permis une
                réflexion approfondie sur mon rôle dans le projet, mettant en lumière mes réussites et les enseignements tirés des difficultés rencontrées.
              </p><br></br></>
            </div>
          </div>

            <div className='matiere' id='management'>
              <div>
                <h6>R502 - Management et assurance qualité (AC3501, AC3502)</h6>
                <><p>
                  Dans le cadre de la matière R502 - Management et assurance qualité (AC3501, AC3502), j'ai
                  assuré <span>la gestion de projet</span> en mettant en œuvre des outils essentiels tels que Git et Jira.
                  En tant que personne en charge de la <span>coordination des membres de l'équipe et de la communication
                    interne</span>, j'ai joué un rôle central dans le bon déroulement du projet.
                </p><br></br></>
                <><p>
                  L'utilisation de <span>Git</span>  a permis une gestion efficace du versionnage du code source,
                  favorisant la collaboration et la traçabilité des modifications. J'ai également utilisé
                  <span> Jira</span> comme outil de gestion de projet, facilitant la <span> planification des tâches</span>, le suivi
                  des avancements et la résolution des problèmes rencontrés tout au long du processus.
                </p><br></br></>
                <><p>
                  Cependant, des défis ont émergé, notamment en ce qui concerne la gestion du temps.
                  La nécessité de consigner toutes les heures de manière précise pour assurer une transparence
                  et une évaluation adéquates du temps passé sur chaque tâche a représenté un défi particulier.
                </p><br></br></>
                <><p>
                  Cette expérience m'a permis de développer des compétences essentielles en <span >gestion de projet</span>, en utilisant
                  des outils modernes et en comprenant l'importance de la communication interne pour assurer une collaboration
                  harmonieuse au sein de l'équipe. La réflexion sur la gestion du temps a été particulièrement instructive,
                  soulignant l'importance de la précision dans le suivi des ressources et des activités pour une gestion de projet efficace.
                </p><br></br></>
              </div>

              <div>
                <ImageGallery items={imagesManagement} />
              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Git</span></div>
                  <div className="outilcadre"><span>Jira</span></div>
                </div>
              </div>
            </div>

            <div className='matiere' id='entrepreneuriat'>
              <div>
                <h6>R503 - Entrepreneuriat (AC3503)</h6>

                <><p>
                  Dans le cadre de la matière R503 - Entrepreneuriat (AC3503), ma contribution a été essentielle dans
                  la mise en place des actions détaillées dans le document présentant notre <span>démarche de travail en "mode entreprise"</span>.
                  J'ai particulièrement été impliquée dans la sélection et l'utilisation des <span> outils de collaboration</span> tels que Google
                  Drive, Discord, Jira, et Git, qui ont joué un rôle crucial dans la gestion efficace du projet.
                </p><br></br></>
                <><p>
                  En tant que personne en charge de la coordination de l'équipe et de la communication interne, mon rôle a été de
                  veiller à la mise en œuvre harmonieuse de ces outils pour favoriser une collaboration fluide entre les membres de l'équipe.
                </p><br></br></>
                <><p>
                  Dans cette perspective, le document élaboré a non seulement consigné les actions concrètes mises en place, mais a également
                  fourni une analyse réfléchie de l'impact de ces choix sur la dynamique de travail de l'équipe. La rigueur dans la documentation
                  des outils de collaboration et des processus adoptés a été cruciale, non seulement pour présenter notre entreprise fictive de
                  manière professionnelle, mais aussi pour démontrer notre capacité à mettre en œuvre des pratiques entrepreneuriales efficaces.

                </p><br></br></>
                <><p>
                  Bien que la création du document puisse ne pas avoir été de ma responsabilité directe, ma contribution dans l'identification,
                  la mise en œuvre et la gestion des outils collaboratifs a été un élément clé pour garantir le succès de notre démarche entrepreneuriale.
                  Cela souligne l'importance de la coordination et de la communication dans le contexte entrepreneurial, des compétences qui se sont avérées
                  précieuses dans le cadre de ce projet multidisciplinaire.

                </p><br></br></>
              </div>
              <div>
                <ImageGallery items={imagesEntre} />
              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Google Drive</span></div>
                  <div className="outilcadre"><span>Discord</span></div>
                  <div className="outilcadre"><span>Jira</span></div>
                  <div className="outilcadre"><span>Git</span></div>
                  <div className="outilcadre"><span>Suite Adobe</span></div>
                </div>
              </div>
            </div>

            <div className='matiere' id='ppp'>
              <div>
                <h6>R504 - Projet personnel et professionnel (AC3504)</h6>

                <><p>
                  Dans le cadre de la matière R504 - Projet personnel et professionnel (AC3504),j’ai élaboré
                  une <span> vidéo pitch</span> pour présenter Kintsugi Tracer. Le processus a débuté par la
                  rédaction méticuleuse d'un script, visant à encapsuler de manière concise les aspects
                  cruciaux du projet. En suivant cette étape, j'ai enregistré ma voix avec l'objectif de transmettre
                  clairement et avec conviction les points saillants du jeu.
                </p><br></br></>
                <><p>
                  L'une des phases délicates de ce processus a été la nécessité de réaliser un <span> montage vidéo</span> pour conférer
                  à la présentation un aspect professionnel et dynamique, il fallait avoir un rendu de qualité pour que le montage soit en accord avec les propos professionnels de la vidéo. Cette étape de post-production a impliqué la sélection
                  judicieuse des séquences audio et vidéo, leur assemblage harmonieux et l'intégration d'éléments visuels pertinents.
                </p><br></br></>
                <><p>
                  En rétrospective, cet exercice m'a permis de développer des compétences essentielles pour la communication
                  professionnelle, soulignant l'importance de pouvoir <span> présenter de manière convaincante</span> un projet tout en
                  démontrant une compréhension approfondie de son contenu.
                </p><br></br></>
              </div>

              <div>
                <a href="https://youtu.be/vYocyQZU8dU">Lien vers la vidéo</a>

              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Davinci Resolve</span></div>
                </div>
              </div>
            </div>

            <div className='matiere' id='front'>
              <div>
                <h6>R505G - Développement front avancé (AC3401, AC3403)</h6>

                <><p>
                  Dans le cadre de la matière R505G - Développement front avancé (AC3401, AC3403), j'ai réalisé
                  <span> un site internet en ReactJS</span>, ayant pour objectif de présenter de manière détaillée les assets
                  3D utilisés dans notre jeu. Ce projet de développement front-end avancé a nécessité une compréhension
                  approfondie de ReactJS, ainsi qu'une maîtrise des concepts avancés du développement web.

                </p><br></br></>
                <><p>
                  La conception du site web a impliqué la création d'une interface utilisateur interactive et
                  conviviale pour présenter les assets 3D de manière attrayante. La gestion optimale des ressources
                  graphiques 3D, tout en garantissant une navigation fluide, a été au cœur des enjeux techniques. J’ai
                  choisi d’aller un peu plus loin que les consignes initiales en présentant non pas uniquement les assets
                  3D, mais le jeu dans son intégralité.

                </p><br></br></>
                <><p>
                  Au cours de ce processus, j'ai rencontré des difficultés pour <span> intégrer du WebGL</span> qui est une API Javascript dans mon react pour de visualiser les assets en 3D dans le navigateur, car nous n'avions pas eu d'exemple en cours, il a donc fallu que je me documente plus sur le sujet pour trouver des modules React adaptés.
                </p><br></br></>
                <><p>
                  En conclusion, cette expérience m'a permis de <span> renforcer mes compétences techniques en ReactJS</span> et d'appréhender les défis
                  spécifiques liés à la présentation de graphiques 3D sur un site web.
                </p><br></br></>
                <><p>
                  Pour plus d'informations sur cette partie du projet, je vous invite à consulter la page dédiée à  <NavLink to="/projet/11">la présentation du site.</NavLink>
                </p><br></br></>
              </div>

              <div>
                <><a href="https://sae-501-react-all-in-one.vercel.app/">Lien vers le site</a> <p>(ce n'est pas le site rendu, mais une version alternative sans connexion à la BDD car il n'y a pas de gestion des droits)</p><br /></>
              </div>
              <div>
                <ImageGallery items={imagesFront} />
              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Visual Studio Code</span></div>
                  <div className="outilcadre"><span>ReactJs</span></div>
                  <div className="outilcadre"><span>Scss</span></div>
                  <div className="outilcadre"><span>WebGL</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='back'>
              <div>
                <h6>R506G - Développement back avancé (AC3402, AC3404)</h6>

                <><p>
                  Dans le cadre de la matière R506G - Développement back avancé (AC3402, AC3404), deux réalisations ont été demandées.
                </p><br></br></>
                <><p>
                  Réalisation d'une API en Symfony :
                </p><br></br></>
                <><p>
                  J’ai développé une <span> API en Symfony</span> permettant la communication entre le front-end du site internet (en ReactJs)
                  et la base de données du jeu. Cette API offre des fonctionnalités essentielles, notamment la possibilité d'ajouter,
                  de modifier, de lire et de supprimer des assets.
                </p><br></br></>
                <><p>
                  Cependant, cette tâche n'a pas été sans défis, notamment la découverte
                  de Symfony et des complications lors du déploiement de l'API. Malgré ces difficultés, l'API a été intégrée avec succès, renforçant ainsi mes compétences en développement back-end avancé.
                </p><br></br></>
                <br></br><br></br>
                <><p>
                  Développement d'une IA (ATIK) dans le jeu :
                </p><br></br></>
                <><p>
                  En parallèle, j'ai développé <span> une intelligence artificielle (IA) baptisée ATIK</span>, servant de guide spirituel dans le jeu. Cette IA a été conçue comme une machine à état,
                  avec des voicelanes adaptées à différentes situations du jeu telles que la mort ou la victoire du joueur.
                </p><br></br></>
                <><p>
                  La réalisation de cette IA a été un défi, notamment dans la conception d'une machine à état qui s'intégrait harmonieusement dans le jeu.
                  La solution trouvée a été d'intégrer la machine à état directement dans le jeu, démontrant ainsi sa nature essentielle et son impact sur
                  l'expérience de jeu. En complément, j'ai créé un <span> document de présentation</span> détaillant le fonctionnement de l'IA et réalisé une vidéo de présentation rapide.
                </p><br></br></>
                <br></br><br></br>
                <><p>
                  Ces deux volets du projet ont été complémentaires, m'offrant une expérience significative dans le développement back-end web avec Symfony ainsi que dans le développement back-end UNITY avec la
                  conception et l'intégration d'une IA en C#. Ces réalisations ont mis en lumière ma capacité à relever des défis techniques variés et à trouver des solutions innovantes pour assurer le succès du projet.
                </p></>
              </div>
              <div>
                <ImageGallery items={imagesBack} />
              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Visual Studio Code</span></div>
                  <div className="outilcadre"><span>Unity</span></div>
                  <div className="outilcadre"><span>C#</span></div>
                  <div className="outilcadre"><span>Symfony (PHP)</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='dispositifs-interactifs'>
              <div>
                <h6>R507G - Dispositifs interactifs (AC3403, AC3404)</h6>

                <><p>
                  Dans le cadre de la matière R507G - Dispositifs interactifs (AC3403, AC3404), deux livrables ont été demandés.
                </p><br></br></>
                <><p>
                  J’ai dû améliorer une application mobile de dessin :
                </p><br></br></>
                <><p>
                  Au cours du semestre dans la matière programmation mobile, j'ai développé une <span> application mobile de dessin</span>.
                  Pour répondre aux exigences de la SAE 501, j'ai amélioré cette application afin de garantir une meilleure conformité aux principes
                  du <span> Modèle-Vue-Contrôleur (MVC)</span>. Ce processus a impliqué une analyse approfondie du code existant, et j'ai proposé des ajustements
                  pour renforcer la propreté du code et assurer la clarté des commentaires. En complément, j'ai élaboré un <span> schéma UML</span> détaillé de l'application,
                  démontrant ainsi ma compréhension approfondie des structures et des relations au sein du projet.
                </p><br></br></>
                <br></br><br></br>
                <><p>
                  Développement du jeu Kintsugi Tracer :
                </p><br></br></>
                <><p>
                  Par ailleurs, j'ai pris en charge le <span> développement du jeu Kintsugi Tracer en C#</span>. Mon rôle a englobé la
                  conception et la mise en œuvre des <span> fonctionnalités du jeu</span>, notamment le déplacement du personnage, la gestion des compétences et des niveaux.
                </p><br></br></>
                <><p>
                  Cette tâche complexe a nécessité un investissement conséquent de <span> 80 heures</span>, principalement dédiées au développement, mais également
                  à la résolution de nombreux bugs rencontrés au cours du processus. La difficulté majeure résidait dans la nécessité de déboguer le
                  code, une étape cruciale pour garantir la stabilité et la qualité du jeu.

                </p><br></br></>
                <><p>
                  Pour plus d'informations sur le jeu créer dans le cadre de cette SAE rendez-vous sur la page dédiée à <NavLink to="/projet/10">la présentation du jeu.</NavLink>

                </p><br></br></>
                <br></br><br></br>
                <><p>
                  Ces deux aspects du projet démontrent ma <span> polyvalence</span> dans la programmation mobile et le développement
                  de jeux, ainsi que ma capacité à m'engager de manière approfondie dans l'analyse, l'amélioration et la création
                  de solutions logicielles complexes. Ces expériences ont renforcé mes compétences en matière de gestion de code,
                  de résolution de problèmes et d'application de principes de conception logicielle.

                </p></>
              </div>

              <div>
                <ImageGallery items={imagesDispo} />
              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Android Studio</span></div>
                  <div className="outilcadre"><span>Java</span></div>
                  <div className="outilcadre"><span>Visual Studio Code</span></div>
                  <div className="outilcadre"><span>UNITY</span></div>
                  <div className="outilcadre"><span>C#</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='hebergement'>
              <div>
                <h6>R508G - Hébergement et cybersécurité (AC3401, AC3405)</h6>

                <><p>
                  Dans le cadre de la matière R508G - Hébergement et cybersécurité (AC3401, AC3405), j’ai <span> hébergé
                    le site internet</span> conçu pour présenter le jeu et les assets 3D. Ce processus, bien que crucial,
                  a rencontré des difficultés majeures, notamment lors du déploiement du back-end. Une des principales
                  contraintes était la nécessité de trouver un hébergeur proposant des solutions facilitant le déploiement spécifique de Symfony.
                </p><br></br></>
                <><p>
                  Le déploiement du back-end Symfony présente souvent des challenges liés à la configuration
                  du serveur, aux dépendances et aux permissions nécessaires pour faire fonctionner l'application de manière optimale.
                </p><br></br></>
                <><p>
                  Au final, malgré les obstacles, la réussite du déploiement a été cruciale pour assurer l'accessibilité
                  et la performance du site internet. Cette expérience a renforcé ma compréhension des enjeux liés à
                  l'hébergement des applications web, soulignant l'importance de choisir des solutions d'hébergement adaptées aux spécificités techniques de chaque projet.

                </p><br></br></>
              </div>
              <div>
                <img src="../media/projets/sae/hebergement.png" alt="Hostinger" />


              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Hostinger</span></div>
                  <div className="outilcadre"><span>Symfony</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='3d'>
              <div>
                <h6>R509G - Conception 3D (AC3403)</h6>

                <><p>
                  Dans le cadre de la matière R509G - Conception 3D (AC3403), j’ai réalisé une partie des<span> assets 3D</span> utilisés dans le jeu.
                  J’ai modélisé deux personnages, dont le personnage principal ainsi que la porte et son animation que l’on retrouve à chaque fin de niveau.

                </p><br></br></>
                <><p>
                  La difficulté principale rencontrée résidait dans la réalisation des assets en low poly, en veillant à minimiser le nombre de polygones
                  tout en garantissant une qualité visuelle satisfaisante. La contrainte de travailler avec un nombre limité de polygones exige une approche
                  minutieuse pour conserver la lisibilité et la cohérence des formes. Parallèlement, l'application correcte des textures était cruciale pour assurer un rendu visuel cohérent et esthétiquement plaisant.
                </p><br></br></>
                <><p>
                  En fin de compte, cette expérience a enrichi mes compétences dans la modélisation d'assets 3D.

                </p><br></br></>
              </div>

              <div>
                <ImageGallery items={images3d} />

              </div>
              <div>
                <h6>Outils utilisés</h6>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Blender</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='conception'>
              <div>
                <h6>R510G - Conception de jeux (AC3403)</h6>

                <><p>
                  Dans le cadre de la matière R510G - Conception de jeux (AC3403), j'ai participé à la création
                  d'un <span> game design document</span> détaillé, présentant l'idéation complète de notre jeu. Ce document
                  englobe les éléments clés tels que le gameplay, la narration, les fonctionnalités, et d'autres aspects essentiels pour le développement du jeu.
                </p><br></br></>
                <><p>
                  La difficulté majeure de cette tâche résidait dans la nécessité de concevoir un jeu réalisable,
                  dont le développement pouvait être achevé dans un délai de trois mois. Cela a demandé une approche stratégique pour définir
                  un projet réalisable tout en préservant l'intégrité de la vision créative du jeu. L'équilibre entre l'ambition du concept et
                  les contraintes de temps et de ressources disponibles était une préoccupation constante. Nous avons donc dû revoir nos ambitions à la baisse en nous contraignant dans nos choix pour concevoir un projet réalisable.
                </p><br></br></>
                <><p>
                  La conception du game design document a nécessité une analyse approfondie des <span> mécaniques de jeu</span>,
                  la définition d'une <span> progression narrative captivante</span>, ainsi que la planification des <span> fonctionnalités essentielles</span>.
                  Il était crucial d'anticiper les possibles obstacles de développement tout en veillant à ce que le jeu offre une expérience immersive et engageante.
                </p><br></br></>
                <><p>
                  En fin de compte, la réalisation de ce game design document a été une expérience significative
                , démontrant ma capacité à traduire une idée créative en un plan de jeu concret, tout en tenant
                  compte des contraintes temporelles. Cette tâche a été une opportunité d'affiner mes compétences
                  en conception de jeux et de gestion de projet, soulignant l'importance de l'équilibre entre ambition
                  artistique et faisabilité pratique dans le processus de conception de jeux.

                </p><br></br></>
              </div>

              <div className=' img-grp'>
                <img src="../media/projets/sae/gdd1.png" alt="Game Design Document" />
                <img src="../media/projets/sae/gdd.png" alt="Game Design Document" />

              </div>
            </div>
            <div className='matiere' id='bilan'>
              <div>
                <h6>Perspectives pour l'avenir</h6>

                <><p>
                  Ce projet universitaire était orienté autour de la création d'un jeu vidéo, même si ce domaine n'est pas un de mes centres d'intérêt principaux, j'ai fortement apprécié développer « Kintsugi Tracer ». Étant passionnée d'intégration web et de programmation web en général, j'ai profité de ce projet et du volet web présent dedans pour développer mes compétences dans ce domaine en allant plus loin que les attentes du professeur.                            </p><br></br></>
                <><p>

                  Afin de valider mon BUT MMI, je vais réaliser un stage de 4 mois en entreprise dans une agence Web. Les compétences que j'ai pu développer dans la SAE501 me seront utiles au cours du stage puisque même si le projet était orienté jeu, en effet, j'ai pu apprendre de nombreuses choses sur la gestion de projet, l'entrepreneuriat, mais aussi comment héberger un site Symfony (ce qui me sera peut-être demandé en stage) et bien sûr, sur le développement web avec l'apprentissage de ReactJs, de WebGL et de Symfony.

                </p><br></br></>

              </div>


            </div>
            <div className='cta'>
              <h6 className="contact">Vous voulez en savoir plus ?&nbsp;
                <NavLink to="/contact" reloadDocument>
                  Me contacter
                </NavLink></h6>

            </div></>
        </div>
      </div></div>
      <Footer /></>
  );
};

export default SAE;