import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import Footer from './Footer';
import Navigation from './Navigation';
const Stage = () => {
  const imagesbenchmark = [
    {
      original: "../media/projets/stage/visiweb/benchmark/1.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/1.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/2.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/2.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/3.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/3.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/4.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/4.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/5.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/5.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/6.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/6.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/7.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/7.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/8.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/8.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/9.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/9.jpg",
    },
    {
      original: "../media/projets/stage/visiweb/benchmark/10.jpg",
      thumbnail: "../media/projets/stage/visiweb/benchmark/10.jpg",
    },
  ];
  const imagesflyer = [
    {
      original: "../media/projets/stage/visiweb/flyer/v-1.png",
      thumbnail: "../media/projets/stage/visiweb/flyer/v-1.png",
    },
    {
      original: "../media/projets/stage/visiweb/flyer/v-2.png",
      thumbnail: "../media/projets/stage/visiweb/flyer/v-2.png",
    },
    {
      original: "../media/projets/stage/visiweb/flyer/v-3.png",
      thumbnail: "../media/projets/stage/visiweb/flyer/v-3.png",
    }
  ];

  return (
    <><div className='bg-stage'>
      <Navigation />
      <div className='Pageprojet pages fade-in'>
        <div className='entete'>
          <div className='haut'>

            <div className='titreprojet'>
              <h2>Stage de fin d'études</h2>
              <p>dans l'agence web Internet Évolution</p>
            </div>


          </div>
          <div className='images images-stage'>
            <img src="../media/projets/stage/okeymaquette.png" alt="Maquette application mobile" />
            <img src="../media/projets/stage/mockup.png" alt="Mockup du site internet démo de VisiWEB" />
            <img src="./media/projets/stage/flyers.png" alt="Flyers pour VisiWEB" />
          </div>
        </div>
        <div className="corps stage">
          <div className='intro'>
            <h4>Stage d'Intégration web et WebDesign</h4>
            <div className="grp-p">
              <p>
                Le stage de fin d'études marque la fin de mon cursus de formation en développement web. Il s'est déroulé au sein de l'agence web Internet Évolution, située à Cournon d'Auvergne. J'ai déjà eu l'occasion de travailler avec cette agence lors de mon stage de deuxième année, et très satisfaite de ce premier stage, je n'ai pas hésité à déposer une seconde candidature spontanée pour y effectuer mon stage de fin d'études.
              </p>
              <p>
                Mes missions principales consistaient à développer et intégrer des sites WordPress, mais aussi à proposer des solutions de web design et de conception graphique.
              </p>
              <p>
                Je vais, sur cette page, vous présenter quelques uns des projets que j'ai réalisés lors de mon stage, en mettant en avant les compétences que j'ai développées et les apprentissages que j'ai tirés de cette expérience professionnelle.
              </p>
            </div>

          </div>
          <div>
            <h5>Sommaire interactif</h5>
            <div className='menustage'>
              <div className='col-menu'>
                <a href="#mcda">Intégrations Wordpress, MCDA</a>
                <a href="#Webdesign">WebDesign, OKey Immobilier et Laguelle</a>
                <ul className='ul-menu'>
                  <li><a href="#Okey">Okey Immobilier</a></li>
                  <li><a href="#laguelle">Laguelle</a></li>
                </ul>
              </div>
              <div className='col-menu'>
                <a href="#Visiweb">VisiWEB, la nouvelle offre d'Internet Évolution</a>
                <ul className='ul-menu'>
                  <li><a href="#Visiweb">VisiWEB, benchmark</a></li>
                  <li><a href="#demo">VisiWEB, site de démonstration</a></li>
                  <li><a href="#flyer">VisiWEB, flyer</a></li>
                  <li><a href="#luxozen">VisiWEB, premier contrat</a></li>
                </ul>

              </div>
              <div id='mcda' ></div>
            </div>
          </div>
          <><div className='matiere mcda' >
            <div>
              <h5>1. Intégrations Wordpress, MCDA</h5>
              <div className='grp-p'>
                <p>
                  Dans le cadre mon stage, j'ai été amenée à DÉVELOPPER des sites Wordpress pour des clients d'Internet Évolution. J'ai réalisé les intégrations en autonomie et j'ai développé mes compétences en <span>Intégration web. </span>
                  <br />
                  Mon objectif était d'intégrer les contenus des clients et de produire des sites web fonctionnels et esthétiques, tout en respectant les délais impartis.
                </p>
                <p>
                  J'ai développé mes compétences en <span>SCSS, PHP et JavaScript</span> pour réaliser des sites web sur-mesure, en accord avec les besoins et les attentes des clients.
                </p>

                <p>
                  Le projet MCDA est un site en cours de développement pour un client spécialisé dans la vente de matériel agricole et d'équipements pour espaces verts. Mon rôle sur ce projet est de personnaliser le WordPress en fonction des besoins du client et des contenus qu'il fournis progressivement.
                </p>
                <p>
                  J'ai débuté par l'installation du template sur WordPress, ce qui m'a permis de comprendre ses fonctionnalités avant d'apporter les modifications nécessaires. En collaboration avec le client j'ai intégré les contenus au fur et à mesure de leur réception.
                </p>
                <p>
                  Au cours du projet, j'ai eu des réunions régulières avec le client pour affiner ma compréhension de ses besoins et ajuster le site en conséquence. J'ai appris à déployer un site grâce à des outils comme cPanel, FileZilla et WP Migrate. J'ai réalisé la mise en recette du site pour permettre au client de le tester et de donner ses retours.
                </p>
                <p>
                  Le site n'étant pas fini, je ne peux pas fournir de lien mais voici quelques images du site provisoire.</p>

                <div className='images-wp'>
                  <img src="../media/projets/stage/mcda/pages-2.png" alt="accueil du site mcda et page contact" />
                  <img src="./media/projets/stage/mcda/pages-1.png" alt="pages du site mcda" />
                </div>
                <p>Cette expérience m'a permis de développer mes compétences en Intégration web mais aussi en<span> gestion de projets web</span>, en particulier dans <span>la gestion des contenus</span> en cours de développement et dans la collaboration client. J'ai appris l'importance de la <span>flexibilité et de l'adaptabilité</span> pour répondre aux besoins évolutifs du client.
                </p>

                <p> Apprentissage critique sollicité lors des projets WordPress </p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>AC34.01</span></div>
                  <div className="outilcadre"><span>AC34.03</span></div>
                  <div className="outilcadre"><span>AC34.05</span></div>
                  <div className="outilcadre"><span>AC35.01</span></div>
                </div>
                <p> Outils utilisés lors des projets WordPress </p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>WordPress</span></div>
                  <div className="outilcadre"><span>SCSS</span></div>
                  <div className="outilcadre"><span>Figma</span></div>
                  <div className="outilcadre"><span>VSCode</span></div>
                  <div className="outilcadre"><span>Teams</span></div>
                </div>
              </div>
            </div>
            <div id='Webdesign'></div>
          </div>
            <div className='matiere' >
              <div>
                <h5>2. WebDesign, OKey Immobilier et Laguelle</h5>
                <div className='grp-p'>
                  <p>
                    <span> Les projets de maquettage</span> représentent un domaine où je me distingue particulièrement au sein de l'entreprise. Grâce à une solide formation artistique acquise notamment lors de mes études en STD2A et en BUT MMI, j'ai développé une véritable fibre artistique qui se reflète dans mes compétences en <span>design graphique et en UX/UI</span>. Mes cours d'UX, de design d'infographie et ma maîtrise des logiciels de conception tels que la <span>suite Adobe et Figma</span> m'ont permis de créer des maquettes, répondant aux besoins et aux attentes de nos clients.
                  </p>
                  <p>
                    J'ai apporté une réelle valeur ajoutée aux projets de l'entreprise. Je suis fiere  d'avoir pu aider l'équipe à décrocher des contrats grâce à mes compétences en design. Ces compétences ont permis à l’entreprise de se rendre aux rendez-vous clients avec des visuels concrets et percutants, ce qui a permis <span>d'appuyer les propositions commerciales de l'entreprise.</span>
                  </p>
                  <p>
                    Voici quelques-unes des maquettes que j'ai réalisées pour les clients de l'agence :
                  </p>
                  <div id='Okey'></div>
                </div>
                <div className='sous-partie pt-20'>
                  <h6>a. Maquette d'application mobile pour Okey Immobilier</h6>
                  <div className='two-cols'>
                    <div className='col-1'>
                      <p>
                        Dans le cadre de ce projet, j'ai dirigé la conception de l'interface graphique en utilisant l'outil Figma, en étroite <span>collaboration avec un développeur mobile.</span> Notre objectif principal était de <span>simplifier le processus de saisie des données</span> tout en offrant une expérience utilisateur attrayante.
                      </p>
                      <p>
                        Pour simplifier l'expérience utilisateur, nous avons créé un <span>tunnel de navigation</span> distinct dédié à la saisie des données, ce qui améliore la fluidité et la convivialité de l'application. Nous avons créé en tout, <span>28 écrans en 4 jours avec un mode clair et un mode sombre.</span>
                      </p>
                      <p>
                        Ce projet m'a permis de <span>consolider mes compétences en design d'applications mobiles et en expérience utilisateur (UX),</span> tout en démontrant ma capacité à travailler efficacement <span>en équipe avec un développeur mobile.</span>
                      </p>
                      <p>
                        Je suis fière d'avoir réussi ce challenge, j'ai su surmonter les difficultés et j'ai été efficace bien que c'était pour moi une <span>première expérience de design d'application mobile.</span>
                      </p>
                      <p><a href="https://www.figma.com/design/76S0Wr0LcR4Md3GL0qc2pd/Okey-Immobilier-maquette-mobile-d%C3%A9mo?node-id=0-1&t=YhMrKRX1aj6Vv1dQ-1" target='_blank' rel="noreferrer">Lien vers le fichier figma</a></p>
                    </div>
                    <div className='col-2'>
                      <img src="./media/projets/stage/okeymaquette.png" alt="" />
                    </div>
                    <div id='laguelle'></div>

                  </div>

                  <br></br>
                  <br></br>

                  <h6>b. Modernisation de Laguelle.com : Conception Web & Mobile</h6>
                  <div className='two-cols'>
                    <div className='col-1'>
                      <p>
                        Dans le cadre de la stratégie de prospection d'Internet Évolution, j'ai dirigé la modernisation du site Laguelle.com, datant de 2015. <span> Mon objectif : concevoir des maquettes web et mobiles attractives pour inciter à la modernisation du site. </span>
                      </p>
                      <p>
                        Pour cela, j'ai pris en charge la conception de maquettes pour la page d'accueil, en me concentrant sur <span>l'aspect visuel et l'expérience utilisateur.</span> J'ai également rédigé du <span>contenu publicitaire percutant</span> pour renforcer l'attrait du site.
                      </p>
                      <p>
                        En utilisant l'outil <span>Figma</span>, j'ai créé plusieurs versions de maquettes, que j'ai ajustées en fonction des retours des dirigeants. Ces maquettes, adaptées aux versions web et mobile, ont été présentées au client, qui a été convaincu par la modernisation proposée.
                      </p>
                      <p>
                        Ce projet m'a permis de <span>développer mes compétences en design responsive et en rédaction publicitaire pour le web.</span> Il a également souligné l'importance de la présentation visuelle pour persuader un client de moderniser son site web.
                      </p>
                      <p><a href="https://www.figma.com/design/SpMoqzy2A7TOz62I5n24fB/Laguelle.com?node-id=27-520&t=WVWRbCtcymLpWfem-1" target='_blank' rel="noreferrer">Lien vers le fichier figma</a></p>
                    </div>


                    <div className='col-2-sm'>
                      <img src="./media/projets/stage/webdesign/laguelle.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grp-p pt-20 ">
                <p> Apprentissage critique sollicité lors des projets de maquettage </p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>AC35.01</span></div>
                  <div className="outilcadre"><span>AC35.02</span></div>
                </div>

                <p> Outils utilisés</p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Figma</span></div>
                  <div className="outilcadre"><span>Teams</span></div>
                </div>
              </div>
              <div id='Visiweb'></div>
            </div>
            <div className='matiere'  >
              <div>
                <h5>3. Visiweb, la nouvelle offre d'Internet Évolution</h5>
                <h6 className='pt-20'>Introduction au projet VisiWEB</h6>
                <p>VisiWEB est une offre innovante d'Internet Évolution, proposant la location d’un site internet vitrine à 49€ HT/mois. Cette solution est spécifiquement destinée aux petites entreprises n’ayant pas les moyens d’acheter un site web à plusieurs milliers d’euros. Mon stage s'est concentré sur divers aspects de ce projet, et j'ai joué un rôle significatif dans sa mise en œuvre.</p>
                <p>
                  Voici quelques-unes des réalisations que j'ai pu faire dans le cadre de ce projet :
                </p>
              </div>

              <div className='pt-20 sous-partie visiweb'  >
                <div>
                  <h6>a. Benchmarking</h6>
                  <div className='grp-p'>
                    <p>
                      Les dirigeants de l'entreprise m'ont tout d'abord chargé de réaliser un benchmark pour regrouper des informations concernant des entreprises qui propose des services de location de site internet.
                    </p>
                    <p>
                      J'ai mené des recherches approfondies et j'ai rassemblé une liste d'enterprises en mettant l'accent sur celles qui affichent leurs prix, ainsi que sur d'autres situées dans la même zone géographique. J'ai recueilli des informations sur les tarifs proposés, les fonctionnalités incluses dans les offres.
                    </p>
                    <p>
                      J'ai ensuite mis en forme les informations pour faciliter leur lecture. Les résultats de cette analyse comparative fournissent à l'entreprise une vue d'ensemble du marché et des options disponibles, leur permettant de prendre des décisions éclairées quant à la mise en œuvre de leur nouveau service de location de sites vitrines. Le tout a été remis sous forme de PDF aux dirigeants de l’entreprise.
                    </p>
                    <p>
                      Cette mission m'a permis de comprendre l'importance de l'analyse concurrentielle dans le développement de nouvelles offres. J'ai appris à identifier les critères pertinents pour comparer les offres, ce qui m'a aidé à structurer ma recherche de manière efficace.
                    </p>
                    <p>Compétences du BUT MMI développées pendant ce projet : Comprendre, Concevoir, Exprimer</p>
                    <div>
                      <ImageGallery items={imagesbenchmark} />
                    </div>
                  </div>
                  <div id='demo'></div>
                </div>

                <div>
                  <h6>b. Réalisations d'un site de démonstration pour la nouvelle offre</h6>
                  <div className='grp-p'>
                    <p>
                      Afin de lancer cette nouvelle offre, on m'a demandé de réaliser un site de démonstration à montrer aux clients prospects. Les dirigeants ont choisi un template WordPress et ils m'ont demandé d'adapter ce template aux besoins de l'offre. Il fallait donc créer un site vitrine adapté aux PME. <br /> J'ai choisi de me baser sur le site d'Internet Évolution pour créer ce site de démonstration.
                    </p>
                    <p>
                      Le but de ce site était de montrer les capacités d'Internet Évolution en matière de site internet, mais aussi d'avoir une base pour l'offre.
                      L'objectif étant de dupliquer ce site afin d'optimiser la création des sites de l'offre. Il a donc fallu à la fois développer un site attrayant, mais aussi optimisé.
                    </p>
                    <p>
                      J'ai développé une quinzaine de pages pour couvrir les différents besoins d'une PME et j'ai optimisé le site à l'aide d'outil comme SCSS qui me permet d'utiliser des variables dans mes feuilles de style.
                    </p>
                    <p>
                      Ainsi, le site est facilement adaptable à n'importe quelle identité graphique, il y a d'autres optimisations au sein de WordPress afin de faciliter l'intégration de contenu redondant comme l'adresse, le numéro de téléphone... Avec de telles optimisations, l'offre VisiWEB a pour objectif de produire un site de qualité en moins d'une journée.
                    </p>
                    <p>Ce projet m'a permis d'acquérir de nouvelles compétences en gestion de projet, contenu et développement web, particulièrement en duplication et personnalisation de sites WordPress.</p>
                    <p>Compétences du BUT MMI développées pendant ce projet : Concevoir, Développer, Entreprendre</p>
                    <div className='images-wp'>
                      <img src="../media/projets/stage/visiweb/demo/pages-1.png" alt="accueil du site demo " />
                      <img src="./media/projets/stage/visiweb/demo/pages-2.png" alt="pages du site demo" />
                    </div>
                    <a href="https://demo.internet-evolution.com/">Lien vers le site de démo</a>
                  </div>
                  <div id='flyer'></div>

                </div>

                <div>
                  <h6>c. Création d’un Flyer de Promotion</h6>
                  <div className='grp-p'>
                    <p>
                      La création du flyer promotionnel a été une excellente opportunité de mettre en pratique mes compétences en design graphique. J'ai travaillé avec des outils comme Photoshop et Illustrator pour mener à bien ce projet.
                    </p>
                    <p>
                      Dans un premier temps, on m'a montré la première version du flyer réalisée par un des dirigeants. Elle manquait de hiérarchie visuelle, mais elle contenait toutes les informations nécessaires. Après avoir cherché des inspirations, j'ai attaqué le design du flyer.
                    </p>
                    <p>
                      J'ai produit deux versions du flyer et je les ai proposées aux dirigeants. Mes propositions les ont surpris, en effet, j'ai réussi à mettre toutes les informations nécessaires dans le flyer en rendant le tout aéré et lisible.
                      Pour cela, j'ai mis en place une hiérarchie visuelle en jouant avec la tailler et l'épaisseur des titres. L'emplacement des éléments a aussi joué un rôle important. À l'aide des formes présentes sur le fond, j'ai pu imposer un sens de lecture.
                    </p>
                    <p>
                      Les dirigeants m'ont fait des retours, j'ai modifié mon flyer et une version finale a vu le jour.
                      <br />
                      Cette version a été envoyée pour impression test. Il s'est avéré qu'il y avait un défaut sur le premier test d'impression. Après avoir cherché pendant de longues minutes, j'ai trouvé d'où venait ce défaut. À l'impression un des éléments du flyer qui avait une opacité réduite, s'imprimait mal, il a fallu trouver une alternative pour avoir le même rendu visuel.
                      <br />
                      Une fois ce problème réglé, nous avons pu faire imprimer en grande quantité.
                    </p>
                    <p>Le flyer que j'ai créé est désormais distribué au client prospect. Je suis fière d'avoir pu le designer.</p>
                    <p>La conception du flyer promotionnel pour VisiWEB a été une expérience enrichissante en matière de design graphique. Utiliser des outils comme Illustrator et Photoshop pour créer un matériel marketing attractif m'a permis de développer mes compétences en communication visuelle. Cette tâche m'a également appris à intégrer des feedbacks et à itérer sur mes designs pour atteindre un résultat final satisfaisant. En fin de compte, j'ai pu produire un flyer efficace qui a été bien reçu par les dirigeants, mais aussi par les prospects.</p>
                    <p>Compétences du BUT MMI développées pendant ce projet : Concevoir, Exprimer</p>
                    <div>
                      <ImageGallery items={imagesflyer} />
                    </div>
                    <a href="https://www.linkedin.com/posts/agence-internet-evolution_webdevelopment-siteinternet-offrespaezciale-activity-7201536193124024321-gmLO/?utm_source=share&utm_medium=member_desktop">Lien vers le post LinkedIn</a>
                  </div>
                  <div id='luxozen'></div>

                </div>

                <div>
                  <h6>d. Premier Contrat VisiWEB - LUXOZEN</h6>
                  <div className='grp-p'>
                    <p>
                      La première offre de VisiWEB a rapidement été signée. On m'a confié la première intégration. Puisque c'était le premier contrat de l'offre, je devais prouver l'efficacité des optimisations.
                    </p>
                    <p>
                      J'ai commencé par dupliquer le site de démonstration, puis je l'ai retravaillé pour intégrer les contenus de la cliente et pour que le site corresponde à l'identité graphique de son entreprise. Sa création m'a pris environ 3 heures.
                    </p>
                    <p>
                      La réalisation du site pour Luxozen a été une validation de notre modèle. Créer un site complet en seulement trois heures a démontré l'efficacité de notre processus.
                    </p>
                    <p>
                      Ce projet m'a permis de consolider mes compétences en développement WordPress et en gestion de contenu, tout en travaillant sous pression. La satisfaction du client et le succès du site Luxozen ont confirmé que l'offre VisiWEB est compétitive et de qualité.
                    </p>
                    <p>Compétences du BUT MMI développées pendant ce projet : Développer</p>
                    <div className='images-wp'>
                      <img src="../media/projets/stage/visiweb/luxozen/pages-1.png" alt="accueil du site luxozen " />
                      <img src="./media/projets/stage/visiweb/luxozen/pages-2.png" alt="pages du site luxozen" />
                    </div>
                    <a href="https://www.cabinetluxozen.fr/">Lien vers le site internet</a>
                  </div>
                </div>



              </div>

              <div className="grp-p pt-20">
                <div className='pt-20'>
                  <h6>Conclusion</h6>
                  <p>
                    Travailler sur le projet VisiWEB m'a permis de développer une gamme diversifiée de compétences, allant de l'analyse de marché à la création de contenus visuels, en passant par l'intégration web et la gestion de projets. Chaque mission m'a apporté des défis uniques et des opportunités d'apprentissage, renforçant ma capacité à gérer des projets complexes de manière efficace. Je suis fière de ce que nous avons accompli et impatiente de voir comment cette offre continuera à se développer et à réussir sur le marché.
                  </p>
                </div>
                <p>Apprentissage critique sollicité lors de ce projet</p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>AC34.03</span></div>
                  <div className="outilcadre"><span>AC34.04</span></div>
                  <div className="outilcadre"><span>AC35.01</span></div>
                  <div className="outilcadre"><span>AC35.02</span></div>
                </div>

                <p>Outils utilisés lors du projet VisiWEB </p>
                <div className='outilutilise'>
                  <div className="outilcadre"><span>Canva</span></div>
                  <div className="outilcadre"><span>WordPress</span></div>
                  <div className="outilcadre"><span>VSCode</span></div>
                  <div className="outilcadre"><span>SCSS</span></div>
                  <div className="outilcadre"><span>Illustrator</span></div>
                  <div className="outilcadre"><span>Photoshop</span></div>
                </div>
              </div>
            </div>
            <div className='matiere' id='bilan'>
              <div className='grp-p'>
                <h5>Conclusion de ce stage de fin d'études</h5>

                <p>
                  Mon stage de fin d'études chez Internet Évolution a été une expérience enrichissante et formatrice, me permettant de développer une gamme diversifiée de compétences. En travaillant sur des projets variés, j'ai pu approfondir mes connaissances en intégration web, en design graphique, en gestion de projet et en développement de contenu. Les défis rencontrés m'ont aidée à renforcer ma flexibilité et mon adaptabilité, notamment dans la gestion des besoins évolutifs des clients.
                </p>
                <p>
                  Les projets sur lesquels j'ai travaillé, comme l'intégration de sites WordPress pour MCDA, la conception de maquettes pour OKey Immobilier et Laguelle, ainsi que le développement de l'offre VisiWEB, ont été autant d'occasions de démontrer et d'améliorer mes compétences techniques et artistiques. J'ai également appris l'importance de la collaboration et de la communication avec les clients et les équipes internes pour atteindre des résultats optimaux.
                </p>
                <p>
                  Au début du 5ème semestre de mon BUT, j'avais une compréhension de base de WordPress, mais au fur et à mesure que j'ai progressé, j'ai pu non seulement maîtriser cet outil, mais aussi l'utiliser efficacement dans des projets complexes.
                </p>
                <p> En conclusion, ce stage a été une étape cruciale dans mon parcours professionnel, confirmant ma passion pour le développement web et le design. Je suis fière des réalisations accomplies et ravie de constater que mes compétences ont été reconnues et appréciées, puisque l'entreprise m'a proposé un CDD. Cela témoigne de leur satisfaction et de la valeur ajoutée que je peux apporter à Internet Évolution. Je suis impatiente de continuer à contribuer à leur succès et de poursuivre ma carrière dans cette entreprise dynamique.</p>
              </div>


            </div>
            <div className='cta'>
              <h5 className="contact">Vous voulez en savoir plus ?&nbsp;
                <NavLink to="/contact" reloadDocument>
                  Me contacter
                </NavLink></h5>

            </div></>
        </div>
      </div></div >
      <Footer /></>
  );
};

export default Stage;