import React from 'react';
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from 'react';

import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
const Contact = () => {
    const form = useRef();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false); // Nouvel état pour gérer l'affichage du message envoyé
   

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_ap5i97l", "template_qwsxuuw", form.current, "SURnQzK3QEbiP4jNZ").then(
            (result) => {
                setIsButtonDisabled(true);
                setIsMessageSent(true); // Mettre à jour l'état pour afficher le message envoyé
            },
            (error) => {
                setIsMessageSent(true); // Mettre à jour l'état pour afficher le message envoyé

            }
        );
    };

    return (
        <><><Navigation />
            <div className="container-contact fade-in-up" >
                <div className="entete-contact">
                    <h3>Discutons de votre projet</h3>
                    <p>N'hésitez pas à me contacter, je réponds en moins de 24 heures</p>
                </div>
                <div className="content-contact">
                    <form ref={form} onSubmit={sendEmail}>
                        <div>
                            <input type="text" name="nom" required placeholder='Nom' />
                            <input type="text" name="prenom" required placeholder='Prénom' />
                        </div>
                        <div>
                            <input type="text" name="sujet" required placeholder='Sujet' />
                            <input type="email" name="email" required placeholder='Email' />
                        </div>
                        <textarea name="message" required placeholder='Entrez votre message ici' />
                     
                            <input
                            className='send'
                              disabled={isButtonDisabled} 
                                type="submit"
                                value="Envoyer" 
                            />
                      
                        <span className={isMessageSent ? 'yes' : 'no'}>
                            {isMessageSent ? 'Message envoyé' : ""}
                        </span>
                    </form>
                </div>
            </div>
        </><Footer /></>
    );
};

export default Contact;