import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './data/ScrollToTop';
import Home from './pages/Home';
import About from './pages/About';
import Projet from './pages/Projet';
import Projets from './pages/Projets';
import Contact from './pages/Contact';
import SAE from './components/SAE';
import Stage from './components/Stage';


const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projets />} />
        <Route path="/projet/:projetId" element={<Projet />} />
        <Route path="/sae501" element={<SAE />} />
        <Route path="/stage" element={<Stage />} />
        {/*  404  */}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;