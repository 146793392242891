import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { NavLink } from 'react-router-dom';

import projectsData from '../data/DataProject';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';


const Projet = () => {
  const { projetId } = useParams();
  const project = projectsData[projetId - 1]; // Les tableaux sont indexés à partir de 0, donc soustrayez 1.

  const [selectedImage, setSelectedImage] = useState(null);


  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (selectedImage !== null) {
      document.body.classList.add('no-scroll'); // Ajoute la classe pour désactiver le défilement
    } else {
      document.body.classList.remove('no-scroll'); // Supprime la classe pour réactiver le défilement
    }
  }, [selectedImage]);


  const images = project.imgprojet.map((imageName) => ({
    original: `/media/projets/${project.nomprojet}/${imageName}`,
    thumbnail: `/media/projets/${project.nomprojet}/${imageName}`,
    alt: project.titreprojet,
  }));
  const totalProjects = projectsData.length;
  var suivproject = project.id + 1;
  var precproject = project.id - 1;
  if (totalProjects < suivproject) {
    suivproject = 1;
  }
  else if (precproject <= 0 || precproject === undefined) {
    precproject = totalProjects
  }


  return (
    <><><Navigation />
      <div className='Pageprojet pages fade-in'>
        <div className='entete'>
          <div className='haut'>
            <NavLink to={`/projet/${precproject}`} smooth="true" duration={500} className="bouton navprojet prec" >
              <img src="/media/icone/flechegauche.svg" alt="fleche" />
              <p >
                Projet précedent
              </p>
            </NavLink>
            <div className='titreprojet'>
              <h2>{project.titreprojet}</h2>
              <p>{project.introprojet}</p>
            </div>
            <NavLink to={`/projet/${suivproject}`} smooth="true" duration={500} className="bouton navprojet suiv" >
              <p >
                Projet suivant
              </p>
              <img src="/media/icone/flechedroite.svg" alt="fleche" />
            </NavLink>

          </div>
          <div className='images'>
            {project.imgentete.map((image) => (
              <img src={image} alt={project.titreprojet} onClick={() => setSelectedImage(image)} />
            ))}
          </div>
        </div>

        <div className='corps'>
          <div>
            <h4>{project.titreprojet}</h4>
            {project.descrprojet && project.descrprojet.map((p) => (
              <><p>{p}</p><br></br></>
            ))}
            {project.lienprojet && project.lienprojet.map((lien) => (
              <><a href={lien}>Lien vers le projet</a><br /></>
            ))}
          </div>

          <div>
            <h6>Outils utilisés</h6>
            <div className='outilutilise'>
              {project.outil && project.outil.map((outil) => (
                <div className="outilcadre"><span>{outil}</span></div>
              ))}
            </div>
          </div>

          <div>
            <h6>Galerie du projet</h6>
            <ImageGallery items={images} />
          </div>

          <div className='cta'>
            <h6 className="contact">Vous voulez en savoir plus ?&nbsp;
              <NavLink to="/contact" reloadDocument>
                Me contacter

              </NavLink></h6>

          </div>

        </div>
        {selectedImage !== null && (
          <div className="lightbox" onClick={handleCloseImage}>

            <img
              src={`${selectedImage}`} // Remplacez par le chemin de votre image
              alt={[selectedImage].alt} // Remplacez par la description de l'image
            />

          </div>
        )}
      </div></><Footer /></>
  );
};

export default Projet;