import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import { useState } from 'react';
import projectsData from '../data/DataProject';
import Projet from '../components/Projet';
import { NavLink } from 'react-router-dom';

const Projets = () => {
    const [selectedRadio, setSelectedRadio] = useState('');
    const radios = ["Front-end", "Fullstack", "Design", "Photographie", "Jeu", "3D"];


    return (
        <><><><Navigation />
        </><div className='page-projets fade-in-up'>
                <div className='entete'>
                    <h3>Mes projets</h3>
                    {/* <hr /> */}
                    <ul className="radio-container">
                        {radios.map((type) => (
                            <li>
                                <input type="radio" id={type} name="typeRadio" checked={type === selectedRadio} onChange={(e) => setSelectedRadio(e.target.id)} />
                                <label htmlFor={type}>{type}</label>
                            </li>
                        ))}
                        <li>
                            <button onClick={() => setSelectedRadio("")}>Annuler la selection</button>
                        </li>
                    </ul>
                </div>


                <div className="container-projets fade-in-up2">
                    {projectsData
                        .filter((project) => selectedRadio === "" || project.type.includes(selectedRadio))
                        .map((project, index) => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <NavLink key={index} to={`/projet/${project.id}`} className="projet-link">
                                <Projet
                                    titre={project.titreprojet}
                                    intro={project.introprojet}
                                    imgSrc={project.imgentete[0]}
                                    comp={project.comprequises}
                                    id={project.id} />
                            </NavLink>
                        ))}
                    <NavLink to={`/sae501`} className="projet-link">
                        <Projet
                            titre="SAE 501 - Jeu Unity"
                            intro="Concevoir un dispositif interactif"
                            imgSrc="../media/projets/sae/1.png"
                            id="sae" />
                    </NavLink>
                    <NavLink to={`/stage`} className="projet-link">
                        <Projet
                            titre="Stage de fin d'étude"
                            intro="dans l'agence web Internet Évolution"
                            imgSrc="../media/projets/stage/1.png"
                            id="stage" />
                    </NavLink>
                </div>

            </div></><Footer /></>
    );
};

export default Projets;