
import React, { useState } from 'react';
import { Link } from 'react-scroll';

import aboutData from '../data/DataAbout';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


const Home = () => {
    const [hoveredImageIndex, setHoveredImageIndex] = useState(null);

    const handleButtonHover = (imageIndex) => {
        setHoveredImageIndex(imageIndex);
    };

    const handleButtonLeave = () => {
        setHoveredImageIndex(null);
    };
    // const containerParcours = useRef(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const parcoursTop = containerParcours.current.getBoundingClientRect().top;
    //         const windowHeight = window.innerHeight;

    
    //         if (parcoursTop +50 < windowHeight) {
    //             containerParcours.current.classList.add('active');
    //         }
           
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // },
    //  []);
    return (
            <><Navigation />
            <div className='container-about'>
            <div className="entete-about  fade-in-up">
                <div className='content-about '>
                    <div className="photo">
                        <img src="/media/portrait.jpg" alt="Oriane" />
                    </div>
                    <div className='entete'>
                        <h1>Hey 👋, Moi c'est Oriane <br />Developpeuse
                            <span> Front-end</span></h1>
                        <p>J'aime l'intégration web et la programmation. Je suis actuellement étudiante en BUT MMI parcours Développement web et dispositifs interactifs ( Métiers du multimédia et de l’Internet ) à Vichy.</p>

                        <Link onMouseEnter={() => handleButtonHover(6)}
                            onMouseLeave={handleButtonLeave} to="/contact" className="boutonfondviolet bouton">
                            <div>
                                Me contacter
                            </div>
                            <img src="/media/icone/fleche.svg" alt="fleche" className={hoveredImageIndex === 6 ? 'shake-horizontal moving-image' : 'nomoving-image'} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="about-comp  fade-in-up2 ">
                <div className="comp">
                    <h4>Compétences</h4>
                    <div>
                        <div className="design">
                            <h5>Design et multimédia</h5>
                            <div className='outilutilise'>
                                {aboutData.adobe.map((language, index) => (
                                    <div className="outilcadre violet" key={index}>{language}</div>
                                ))}
                            </div>
                            <div className='outilutilise'>
                                {aboutData.design.map((language, index) => (
                                    <div className="outilcadre violet2" key={index}>{language}</div>
                                ))}
                            </div>
                            <div className='outilutilise'>
                                {aboutData.modelisation.map((language, index) => (
                                    <div className="outilcadre violet3" key={index}>{language}</div>
                                ))}
                            </div>
                        </div>
                        <div className="lang-framework">
                            <h5>Développement</h5>
                            <div className='outilutilise'>
                                {aboutData.front.map((language, index) => (
                                    <div className="outilcadre violet" key={index}>{language}</div>
                                ))}
                            </div>
                            <div className='outilutilise'>
                                {aboutData.back.map((language, index) => (
                                    <div className="outilcadre violet2" key={index}>{language}</div>
                                ))}
                            </div>
                            <div className='outilutilise'>
                                {aboutData.framework.map((language, index) => (
                                    <div className="outilcadre violet3" key={index}>{language}</div>
                                ))}
                            </div>
                            <div className='outilutilise'>
                                {aboutData.outil.map((language, index) => (
                                    <div className="outilcadre violet4" key={index}>{language}</div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="about-experiences " >
                <h4>Expériences professionelles</h4>
                <div className='experiences'>
                    {Object.values(aboutData.experience).map((experience, index) => (
                        <div className="experience" key={index}>
                            <h6>{experience.nom}</h6>
                            <span>{experience.annee}</span>
                            <p>{experience.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="about-parcours ">
                <h4>Parcours scolaire</h4>
                <div className='diplomes'>
                    {Object.values(aboutData.parcours).map((parcours, index) => (
                        <div className="diplome" key={index}>
                            <h6>{parcours.nom}</h6>
                            <span>{parcours.annee}</span>
                            <p>{parcours.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div></>
    );
};

export default Home;